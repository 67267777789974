import React from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import { useTranslation } from 'react-i18next';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { PortfolioShowcaseWrapper, PortfolioShowcaseItem } from './howTo.style';

import image1 from '../../../images/howto1.png';
import image2 from '../../../images/howto2.png';
import image3 from '../../../images/howto3.png';
import image4 from '../../../images/howto4.png';

const PortfolioShowcase = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle
}) => {
  const carouselOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
    animationDuration: 900
  };

  const { t } = useTranslation();
  const tab = [
    {
      title: t('howTo.tab1.label'),
      portfolioItem: [
        {
          title: t('howTo.tab1.topic'),
          description: t('howTo.tab1.desc'),
          image: image1,
          link: '#',
          featuredIn: 'AWWWARDS',
          featuredLink: '#',
          view: '4.5K',
          love: '1.5K',
          feedback: '1.2K',
          buildWith: []
        }
      ]
    },
    {
      title: t('howTo.tab2.label'),
      portfolioItem: [
        {
          title: t('howTo.tab2.topic'),
          description: t('howTo.tab2.desc'),
          image: image2,
          link: '#',
          featuredIn: 'AWWWARDS',
          featuredLink: '#',
          view: '4.5K',
          love: '1.5K',
          feedback: '1.2K',
          buildWith: []
        }
      ]
    },
    {
      title: t('howTo.tab3.label'),
      portfolioItem: [
        {
          title: t('howTo.tab3.topic'),
          description: t('howTo.tab3.desc'),
          image: image3,
          link: '#',
          featuredIn: 'AWWWARDS',
          featuredLink: '#',
          view: '4.5K',
          love: '1.5K',
          feedback: '1.2K',
          buildWith: []
        }
      ]
    },
    {
      title: t('howTo.tab4.label'),
      portfolioItem: [
        {
          title: t('howTo.tab4.topic'),
          description: t('howTo.tab4.desc'),
          image: image4,
          link: '#',
          featuredIn: 'AWWWARDS',
          featuredLink: '#',
          view: '4.5K',
          love: '1.5K',
          feedback: '1.2K',
          buildWith: []
        }
      ]
    }
  ];
  return (
    <Box {...sectionWrapper} as="section" id="portfolio_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading
            {...secTitle}
            content={t('howTo.topic')}
            style={{ color: '#ffffff' }}
          />
          <Text
            {...secDescription}
            content={t('howTo.topicDesc')}
            style={{ color: '#496b96' }}
          />
        </Box>

        <PortfolioShowcaseWrapper>
          <Tabs
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent animated={false} />}
          >
            {tab.map((tabItem, index) => (
              <TabPane
                tab={
                  <Text
                    content={tabItem.title}
                    data-text={tabItem.title}
                    as="span"
                  />
                }
                key={index + 1}
              >
                <GlideCarousel
                  carouselSelector={`portfolio-showcase-carousel-${index + 1}`}
                  options={carouselOptions}
                >
                  <>
                    {tabItem.portfolioItem.map((portfolioItem, index) => (
                      <GlideSlide key={index}>
                        <PortfolioShowcaseItem>
                          <Box {...portfolioImage}>
                            <img
                              src={portfolioItem.image}
                              alt={t(`howTo.tab${index + 1}.label`)}
                              style={{ maxWidth: '100%' }}
                            />
                          </Box>
                          <Box {...portfolioDetails}>
                            <Heading
                              content={portfolioItem.title}
                              {...titleStyle}
                              style={{ color: '#ffffff', fontSize: '34px' }}
                            />
                            <Text
                              content={portfolioItem.description}
                              {...detailsStyle}
                              style={{ color: '#496b96', fontSize: '20px' }}
                            />
                          </Box>
                        </PortfolioShowcaseItem>
                      </GlideSlide>
                    ))}
                  </>
                </GlideCarousel>
              </TabPane>
            ))}
          </Tabs>
        </PortfolioShowcaseWrapper>

        {/* <Heading
          {...secTitle}
          content="ลองดูวิดีโอวิธีการออก Order วิธีการใช้ One Option"
          style={{ color: '#ffffff', marginTop: '100px', marginBottom: '40px' }}
        />
        <VideoWrapper>
          <iframe
            title="วิธีการใช้งานระบบ One Option"
            src="https://www.youtube.com/embed/rd61bqPVyL8"
            frameBorder="0"
          />
        </VideoWrapper> */}
      </Container>
    </Box>
  );
};

PortfolioShowcase.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object
};

PortfolioShowcase.defaultProps = {
  sectionWrapper: {
    pt: ['120px', '120px', '120px', '120px', '150px'],
    pb: ['60px', '80px', '100px', '110px', '150px']
  },
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: ['50px', '65px']
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px']
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0'
  },
  portfolioImage: {
    width: [1, 1, 1 / 2]
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px']
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px'
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0'
  }
};

export default PortfolioShowcase;
