import React from 'react';
import { useTranslation } from 'react-i18next';
import HowTo from '../containers/Custom/HowTo';
import MainLayout from '../layouts/main';

export default function() {
  const { t } = useTranslation();
  return (
    <MainLayout title={t('howTo.title')}>
      <HowTo />
    </MainLayout>
  );
}
